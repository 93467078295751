// Object for global variables
var Vars = Vars || {};

// Default ease
TweenLite.defaultEase = Expo.easeOut;

// Global DOM variables which doesn't fit in `Vars`
var $document = $(document),
		$window = $(window),
		$html = $('html'),
		$body = $('body'),
		$htmlBody = $html.add($body),
		$modal = $('.modaal-open');


/**
 * Global init function
 * @param  {string} parent 		segment_1 of the URL
 * @param  {string} child   	segment_2 of the URL
 */
App = function(parent, child) {
	'use strict';

	// Create new instance if function called withour `new`
	if (!(this instanceof App)) return new App(parent, child);
	var _ = this;

	Vars.parent = parent.length ? parent : 'home';
	Vars.child = child;



	/* ================================
	 * Dev stuff, to remove on prod
	 * ================================ */

	// Prevent scrollTop on click on a[href="#"] links
	$('a[href="#"]').on('click', function(e) {
		e.preventDefault();
	});

	/* ================================
	 * ./Dev stuff, to remove on prod
	 * ================================ */



	/* ================================
	 * Init global
	 * ================================ */

	// Smooth scroll for anchors link
	$('a[href*=#]:not([href=#])').on('click', Utils.smoothScroll);


	Vars.bLazy = new Blazy({
    selector: 'img[data-src],.js-lazy',
    offset: 300,
    successClass: 'is-lazy-loaded',
    errorClass: 'is-lazy-error',
  });


	$window.on({
		load: function() {
      // window load
      $('.tooltip__button').on('click', function() {
        $('.tooltip__content').toggle();
      });
		},
		// Create throttled events
		resize: Utils.addEndEvent,
		scroll: Utils.addEndEvent
	});


	/*
	 * Form group
	 */

	$document.on('focusin change', '.field-text', function () {
		var $this = $(this),
			$group = $this.parent();

		$group.addClass('is-active');
	}).on('focusout change', '.field-text', function () {
		var $this = $(this),
			$group = $this.parent();

		if ( $this.val() == "" ) {
			$group.removeClass('is-active');
		}
	});


	/* ================================
	 * ./Init global
	 * ================================ */


	$document.on('focusout change', 'input[required]', function (e) {
		var $this = $(this),
			pattern = $this.attr('pattern'),
			$parent = $this.closest('.field-group');

		if ( $this.val() == '' ) {
			$parent.addClass('is-fail').removeClass('is-valid');
		} else if ( typeof pattern != 'undefined' ) {
			pattern = new RegExp(pattern);
			if ( !pattern.test($this.val()) ) {
				$parent.addClass('is-fail').removeClass('is-valid');
			} else {
				$parent.removeClass('is-fail').addClass('is-valid');
			}
		} else {
			$parent.removeClass('is-fail').addClass('is-valid');
		}
	});


	/* ================================
	 * Init pages
	 * ================================ */

	Utils.hasMethod(Vars.parent, Vars.child);

	/* ================================
	 * ./Init page
	 * ================================ */

	return _;
};
